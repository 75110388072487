import moment from 'moment';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardTitle, Container,
  Form
} from 'reactstrap';
import NewEntry from '../models/NewEntry';
import Token from '../models/Token';
import DateSelectBar from './DateSelectBar';
import TimeStartAndEndSelect from './TimeStartAndEndSelect';
import {EntryType} from "../models/EntryType";

interface Props {
  mode: EntryType;
  token: Token;
  date: moment.Moment;
  serverResponse: string;
  btnFunction: (date: moment.Moment, entries: NewEntry[]) => void;
}
const ContentNewEntry = (p: Props) => {
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(p.date);
  const [listOfEntries, setListOfEntries] = useState<NewEntry[]>([NewEntry.createWithDefault()])

  return (
    <Container>
      <Card className="mt-3 mb-3">
        <CardBody>
          <Container style={{ justifyContent: "center", display: "grid" }}>
            <DateSelectBar selectedDate={selectedDate} onDateChange={(changedDate) => setSelectedDate(changedDate)} />
          </Container>
          <CardTitle tag="h4">{p.mode === "entries" ? "Zeiten" : "HomeOffice-Zeiten"} am {selectedDate.format("DD.MM.")} eintragen</CardTitle>
          <Form>
            {
              listOfEntries.map((entry, index) =>
                <TimeStartAndEndSelect
                  key={index}
                  index={index + 1}
                  startHours={entry.startHours}
                  startMinutes={entry.startMinutes}
                  endHours={entry.endHours}
                  endMinutes={entry.endMinutes}
                  onChange={(sh, sm, eh, em) => {
                    setListOfEntries(listOfEntries.map((e, i) => i === index ? new NewEntry(sh, sm, eh, em) : e))
                  }} />
              )
            }
            <Button
              color="secondary"
              onClick={() => {
                const list = listOfEntries.map(e => e);
                list.pop();
                setListOfEntries(list);
              }}
              disabled={listOfEntries.length === 1}
              outline>Letzten Eintrag löschen</Button>
            <Button
              color="warning"
              onClick={() => {
                const list = listOfEntries.map(e => e);
                list.push(NewEntry.createWithDefault());
                setListOfEntries(list);
              }}
              outline>Weiteren Eintrag erfassen</Button>
            <Container className="my-3" >
              <Alert color="danger" hidden={p.serverResponse.length === 0}>
                {p.serverResponse}
              </Alert>
            </Container>
            <Button
              color="secondary"
              onClick={() => { history.goBack() }}>Zurück</Button>
            <Button
              className="float-right"
              color="warning"
              onClick={() => p.btnFunction(selectedDate, listOfEntries)}
              disabled={listOfEntries.some(e => !e.isValid())}>Verbindlich eintragen</Button>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
}

export default ContentNewEntry;
