import axios from "axios";
import AbsenceCount from "../models/AbsenceCount";
import AbsenceInMonth from "../models/AbsenceInMonth";
import NewAbsence from "../models/NewAbsence";
import Token from "../models/Token";
import UserAbsence from "../models/UserAbsence";
import {CalendarType} from "../models/EntryType";

const UNKNOWN_ERROR_TEXT = 'Ein unerwarteter Fehler ist aufgetreten.';
export interface AbsenceCountResponse { authError: boolean; absenceCount: AbsenceCount };
export interface AbsenceInMonthResponse { authError: boolean; absences: AbsenceInMonth[] };
export interface AllUserAbsencesResponse { authError: boolean; absences: UserAbsence[] };
export interface DeleteAbsenceResponse { authError: boolean; success: boolean };
export interface CreateAbsenceResponse { authError: boolean; success: boolean };

const AbsenceHomeofficeService = {
  getAbsenceCount: async function getAbsenceCount(token: Token, date: string): Promise<AbsenceCountResponse> {
    try {
      const response = await axios.get(
        `/api/absences/count`,
        {
          params: {
            date: date,
          },
          headers: {
            Authorization: `Bearer ${token.jwt}`
          }
        }
      );
      return Promise.resolve({ authError: false, absenceCount: response.data });
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, absenceCount: { date: 20210101, count: -1 } })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
  getEntriesInMonth: async function getEntriesInMonth(token: Token, yearmonth: number, mode: CalendarType): Promise<AbsenceInMonthResponse> {
    try {
      const response = await axios.get(
        `/api/${mode}/month`,
        {
          params: {
            yearmonth: yearmonth,
          },
          headers: {
            Authorization: `Bearer ${token.jwt}`
          }
        }
      );
      return Promise.resolve({ authError: false, absences: response.data });
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, absences: [] })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
  getAbsences: async function getAbsences(token: Token): Promise<AllUserAbsencesResponse> {
    try {
      const response = await axios.get(
        `/api/users/${token.userId}/absences`,
        {
          headers: {
            Authorization: `Bearer ${token.jwt}`
          }
        }
      );
      return Promise.resolve({ authError: false, absences: response.data });
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, absences: [] })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
  getHOEntries: async function getHOEntries(token: Token): Promise<AbsenceInMonthResponse> {
    try {
      const response = await axios.get(
          `/api/users/${token.userId}/homeofficeentries`,
          {
            headers: {
              Authorization: `Bearer ${token.jwt}`
            }
          }
      );
      return Promise.resolve({ authError: false, absences: response.data });
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, absences: [] })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
  createAbsence: async function createAbsence(token: Token, absence: NewAbsence): Promise<CreateAbsenceResponse> {
    // date format = yyyyMMDD = 20210321
    try {
      await axios.post(
        `/api/users/${token.userId}/absences`,
        absence.toExportFormat(),
        {
          headers: {
            Authorization: `Bearer ${token.jwt}`
          }
        }
      );
      return Promise.resolve({ authError: false, success: true })
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, success: false })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
  deleteUserAbsenceOrHOEntryForever: async function deleteUserAbsenceForever(token: Token, absenceId: number, mode: CalendarType): Promise<DeleteAbsenceResponse> {
    try {
      await axios.delete(
        `/api/users/${token.userId}/${mode === "absences" ? "absences" : "homeofficeentries"}/${absenceId}`,
        {
          headers: {
            Authorization: `Bearer ${token.jwt}`
          }
        }
      );
      return Promise.resolve({ authError: false, success: true })
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, success: false })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
}

export default AbsenceHomeofficeService;
