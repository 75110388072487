import moment from 'moment';
import { useEffect, useState } from 'react';
import {
    useHistory
} from 'react-router-dom';
import {
    Button, Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    UncontrolledTooltip
} from 'reactstrap';
import AbsenceCount from '../models/AbsenceCount';
import CurrentVehicleStatus from '../models/CurrentVehicleStatus';
import Token from '../models/Token';
import AbsenceHomeofficeService from '../services/AbsenceHomeofficeService';
import StatusService from '../services/StatusService';

interface Props {
    token: Token;
    tokenExpiredCallback: () => void;
    logoutFunction: () => void;
}

const REFRESH_INTERVAL_IN_MILLISECONDS = 90 * 1000;

const AppBar = (p: Props) => {
    const history = useHistory();
    const [currentVehicleStatus, setCurrentVehicleStatus] = useState<CurrentVehicleStatus[]>([]);
    const [absenceCount, setAbsenceCount] = useState<AbsenceCount>({ date: 20210101, count: 0 });

    useEffect(() => {
        loadNumberOfAbsences();
        updateCurrentStatus();
        const intervalFunction = setInterval(() => {
            updateCurrentStatus();
        }, REFRESH_INTERVAL_IN_MILLISECONDS);

        return () => clearInterval(intervalFunction);
    }, [p.token.userId])

    function updateCurrentStatus(): void {
        if (p.token.isValid()) {
            StatusService.getCurrentStatus(p.token).then(
                v => {
                    if (!v.authError) {
                        setCurrentVehicleStatus(v.currentVehicleStatus);
                    } else {
                        p.tokenExpiredCallback();
                    }
                }
            ).catch(e => { console.log(e) });
        }
    }

    function loadNumberOfAbsences() {
        if (p.token.isValid()) {
            AbsenceHomeofficeService.getAbsenceCount(p.token, moment().locale('de').format('YYYYMMDD')).then(
                v => {
                    if (!v.authError) {
                        setAbsenceCount(v.absenceCount);
                    } else {
                        p.tokenExpiredCallback();
                    }
                }
            ).catch(e => { console.log(e) });
        }
    }

    return (
        <Navbar color='dark' dark>
            <NavbarBrand onClick={() => {
                history.push('/')
            }}>Einsatzbereitschaftstool</NavbarBrand>

            <Nav className="ml-auto" pills>
                {p.token.isValid() && (p.token.isAdmin || p.token.isTV) &&
                    <NavItem className="mt-1" onClick={() => history.push('/tv')}>
                        <Button outline color='warning' size="sm">TV-Modus</Button>
                    </NavItem>
                }
                {p.token.isValid() && p.token.isAdmin &&
                    <NavItem className="ml-2 mt-1" onClick={() => history.push('/protocol')}>
                        <Button outline color='warning' size="sm">Protokoll</Button>
                    </NavItem>
                }
                {p.token.isValid() && p.token.isAdmin &&
                    <NavItem className="ml-2 mt-1" onClick={() => history.push('/user-activation')}>
                        <Button outline color='warning' size="sm">Nutzermanagement</Button>
                    </NavItem>
                }
                {p.token.isValid() &&
                    <NavItem className="ml-2 mt-1" onClick={() => history.push('/homeoffice-calendar')}>
                        <Button outline color='warning' size="sm">HomeOffice</Button>
                    </NavItem>
                }
                {p.token.isValid() && <NavItem className="ml-2" key="absence_count_key" id="absence_count_id">
                    <div className="small" style={
                        {
                            textAlign: "center",
                            color: "#ffc107"
                        }
                    } >{absenceCount.count}</div>
                    <div className="small" style={{ color: "#ffc107" }}>Abwesend</div>
                    <UncontrolledTooltip placement="bottom" target={"absence_count_id"}>
                        <div>
                            Heute sind {absenceCount.count} Personen abwesend.
                        </div>
                    </UncontrolledTooltip>
                </NavItem>}
                {p.token.isValid() && currentVehicleStatus.map(v =>
                    <NavItem className="ml-2" key={v.id} id={`StatusColorDotv${v.id}`}>
                        <div className="small" style={{ color: "#ffc107" }}>{v.vehicleName}</div>
                        <div className="small font-weight-bold" style={
                            {
                                backgroundColor: v.colorCode,
                                borderRadius: "8%",
                                borderWidth: "1px",
                                borderStyle: "solid",
                                borderColor: "#ffc107",
                                minHeight: "1rem",
                                minWidth: "3rem",
                                textAlign: "center",
                                color: "#000000"
                            }
                        } >{v.statusNumber}</div>
                        <UncontrolledTooltip placement="bottom" target={`StatusColorDotv${v.id}`}>
                            {v.statusNumber === -1 ? 'Aktueller Status ist unbekannt' : (
                                <>
                                    <div className="font-weight-bold">
                                        Aktueller Status ist {v.statusNumber}
                                    </div>
                                    {v.message !== '' && <div>
                                        {v.message}
                                    </div>}
                                    <div className="font-italic">
                                        {moment.unix(v.timestamp).format('ddd HH:mm')} Uhr
                                    </div>
                                </>
                            )
                            }
                        </UncontrolledTooltip>
                    </NavItem>
                )
                }
                {p.token.isValid() &&
                    <NavItem className="ml-2 mt-1" onClick={() => history.push('/profile')}>
                        <Button outline color="warning" size="sm">Profil</Button>
                    </NavItem>
                }
                {p.token.isValid() &&
                    <NavItem className="mx-2 mt-1" onClick={() => {
                        history.push('/')
                        p.logoutFunction()
                    }}>
                        <Button outline color="warning" size="sm">Logout</Button>
                    </NavItem>
                }
            </Nav>
        </Navbar>
    );
}

export default AppBar;