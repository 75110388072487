import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardTitle, Container,
    FormText,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';
import Token from '../models/Token';
import UserProfile from '../models/UserProfile';
import UserService from '../services/UserService';

interface Props {
    token: Token;
    tokenExpiredCallback: () => void;
}
const ContentUserManagement = (p: Props) => {
    const history = useHistory();
    const [listOfUsers, setListOfUsers] = useState<UserProfile[]>([])

    useEffect(() => {
        UserService.getAllUserProfiles(p.token).then(
            v => {
                if (!v.authError) {
                    setListOfUsers(v.entries);
                } else {
                    p.tokenExpiredCallback()
                }
            }
        )
    }, [])

    return (
        <Container>
            <Card className="mt-3 mb-3">
                <CardBody>
                    <CardTitle tag="h4">Nutzerprofile aktivieren oder deaktivieren</CardTitle>
                    <FormText color="muted" className="mb-2">
                        Angemeldete Nutzer müssen sich einmal ausloggen, um einen neuen Token zu bekommen und damit die Statusänderungen visuell erhalten.
                    </FormText>
                    <ListGroup>
                        {
                            listOfUsers.map((user) =>
                                <ListGroupItem key={user.id} color={user.activated ? "success" : undefined}>
                                    <span className="mr-3">[ID {user.id}]</span> {user.firstName} {user.lastName} - {user.email}
                                    <Badge className="mx-2" color="dark" pill>{user.activated ? "aktiv" : "nicht aktiv"}</Badge>

                                    <Button size="sm" className='float-right ml-4' color="danger" onClick={() => {
                                        const confirmResult = window.confirm(`Wollen Sie wirklich das folgende Nutzerprofil löschen?\n\nID:         ${user.id}\nName: ${user.firstName} ${user.lastName}\nE-Mail: ${user.email}`);
                                        if (confirmResult) {
                                            UserService.deleteUserProfileForever(p.token, user.id).then((response) => {
                                                if (response.authError) {
                                                    p.tokenExpiredCallback();
                                                    return
                                                }
                                                const updatedListOfUsers: UserProfile[] = [];
                                                listOfUsers.forEach(e => {
                                                    if (e.id !== user.id) {
                                                        updatedListOfUsers.push(e);
                                                    }
                                                });
                                                setListOfUsers(updatedListOfUsers)
                                            }).catch(e => console.log(e.toString()));
                                        }
                                    }}>Löschen</Button>

                                    <Button size="sm" className='float-right' color="warning" onClick={() => {
                                        UserService.toggleUserProfileActivation(p.token, user.id).then((response) => {
                                            if (response.authError) {
                                                p.tokenExpiredCallback();
                                                return
                                            }
                                            const updatedListOfUsers: UserProfile[] = [];
                                            listOfUsers.forEach(e => {
                                                if (e.id === user.id) {
                                                    updatedListOfUsers.push(new UserProfile(e.id, e.firstName, e.lastName, e.email, e.password, e.roles, !e.activated));
                                                } else {
                                                    updatedListOfUsers.push(e);
                                                }
                                            });
                                            setListOfUsers(updatedListOfUsers)
                                        }).catch(e => console.log(e.toString()));
                                    }}>{user.activated ? "Deaktivieren" : "Aktivieren"}</Button>
                                </ListGroupItem>
                            )
                        }
                    </ListGroup>
                </CardBody>
            </Card>
        </Container >
    );
}

export default ContentUserManagement;
