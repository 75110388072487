import { emailRegex } from "./UserLogin";

class UserProfile {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    roles: string[];
    activated: boolean;
    constructor(id: number, firstName: string, lastName: string, email: string, password: string, roles: string[], activated: boolean) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.password = password;
        this.roles = roles;
        this.activated = activated;
    }

    static empty(): UserProfile {
        return new UserProfile(-1, '', '', '', '', [], false);
    }

    isFilledOrHint(): string {
        if (this.firstName === '') {
            return 'Vorname ist nicht gefüllt.';
        }
        if (this.lastName === '') {
            return 'Nachname ist nicht gefüllt.';
        }
        if (this.email === '') {
            return 'E-Mail ist nicht gefüllt.';
        }
        if (!emailRegex.test(this.email)) {
            return 'E-Mail ist nicht korrekt.';
        }
        if (this.password === '') {
            return 'Passwort ist nicht gefüllt.';
        }
        return '';
    }
}

export default UserProfile;
