import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Route, Switch
} from 'react-router-dom';
import './App.css';
import AppBar from './components/AppBar';
import ContentCalendar from './components/ContentCalendar';
import ContentLogin from './components/ContentLogin';
import ContentUserProfile from './components/ContentUserProfile';
import Token from './models/Token';
import UserLogin from './models/UserLogin';
import UserProfile from './models/UserProfile';
import "moment/locale/de";
import { createTheme, ThemeProvider } from '@material-ui/core';
import UserService, { UpdateProfileResponse } from './services/UserService';
import { Alert, Container } from 'reactstrap';
import ContentUserManagement from './components/ContentUserManagement';
import ContentProtocol from './components/ContentProtocol';
import logo from './logo.svg';
import moment from 'moment';

moment.locale("de")

const defaultMaterialTheme = createTheme({
  palette: {
    primary: {
      main: "#282c34",
      contrastText: "#fff",
    }
  },
});

const App = () => {
  const [token, setToken] = useState<Token>(Token.createWithTokenFromStorage());
  const [generalMessage, setGeneralMessage] = useState<string>('');
  const [loginMessage, setLoginMessage] = useState<string>('');
  const [registerMessage, setRegisterMessage] = useState<string>('');
  const [profileUpdateMessage, setProfileUpdateMessage] = useState<string>('');

  function tokenExpiredCallback() {
    setToken(new Token(''));
    resetAllMessages();
    setGeneralMessage('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.');
  }

  function resetAllMessages() {
    setGeneralMessage('');
    setRegisterMessage('');
    setLoginMessage('');
    setProfileUpdateMessage('');
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils} locale={"de"}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <Router>
          <AppBar
            token={token}
            tokenExpiredCallback={tokenExpiredCallback}
            logoutFunction={() => {
              setToken(new Token(''));
              resetAllMessages();
            }} />
          <Container className="my-3" >
            <Alert color="info" hidden={generalMessage.length === 0} >
              {generalMessage}
            </Alert>
          </Container>
          {!token.isValid() &&
            <Switch>
              <Route path="/register">
                <ContentUserProfile
                  token={token}
                  headline={'Erstelle einen Account'}
                  isUserProfile={false}
                  btnText={'Account erstellen'}
                  serverResponse={registerMessage}
                  btnFunction={(userProfile: UserProfile) => {
                    UserService.register(userProfile).then(r => {
                      setGeneralMessage(r);
                      setRegisterMessage('');
                    }).catch(e => {
                      setRegisterMessage(e.toString());
                    })
                  }}
                  tokenExpiredCallback={tokenExpiredCallback} />
              </Route>
              <Route path="/">
                <ContentLogin
                  loginMessage={loginMessage}
                  loginFunction={(userLogin: UserLogin) => {
                    UserService.login(userLogin).then(t => {
                      setGeneralMessage('');
                      setLoginMessage('');
                      setToken(t);
                      if (!t.isUserActive) {
                        setGeneralMessage('Dein Nutzerprofil ist noch nicht aktiv, warte bis ein Admin dich freigeschaltet hat. Bis dahin kannst du keine Einträge erstellen.')
                      }
                    }).catch(e => setLoginMessage(e.toString()));
                  }} />
              </Route>
            </Switch>}
          {token.isValid() &&
            <Switch>
              <Route path="/user-activation">
                <ContentUserManagement token={token} tokenExpiredCallback={tokenExpiredCallback} />
              </Route>
              <Route path="/protocol">
                <ContentProtocol token={token} tokenExpiredCallback={tokenExpiredCallback} />
              </Route>
              <Route path="/profile">
                <ContentUserProfile
                  token={token}
                  headline={'Kontrolliere deine Angaben'}
                  isUserProfile={true}
                  btnText={'Angaben speichern'}
                  serverResponse={profileUpdateMessage}
                  btnFunction={(userProfile: UserProfile) => {
                    UserService.updateProfile(token, userProfile).then((res: UpdateProfileResponse) => {
                      if (res.authError) {
                        setToken(new Token(''));
                        setGeneralMessage('Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.');
                      }
                      alert('Nutzerprofil erfolgreich geändert!');
                      setProfileUpdateMessage('');
                    }).catch(e => setProfileUpdateMessage(e.toString()));
                  }}
                  tokenExpiredCallback={tokenExpiredCallback} />
              </Route>
              <Route path="/">
                <ContentCalendar token={token} tokenExpiredCallback={tokenExpiredCallback} />
              </Route>
            </Switch>
          }
          <footer className="page-footer font-small">
            <div className="text-center pt-3">
              {token.isValid() && <>
                {/*
                  Die Links innerhalb der <> werden nur angezeigt, wenn ein Nutzer eingeloggt ist.
                  Achtung: Die Links sind nur versteckt, aber im js-Bundle enthalten.
                  Da das js-Bundle öffentlich zugänglich ist, könnte man mit etwas Aufwand die Links extrahieren.
                */}
                <a className="mx-2" href="https://hosting.ondataport.de/florian2ssoalternative/api/sybosssourl">SYBOS</a>
                <a className="mx-2" href="https://fhhportal.ondataport.de/websites/0030/intern/Seiten/Startseite.aspx">FHH-Portal</a>
                <a className="mx-2" href="https://fvffniendorf-my.sharepoint.com/:f:/g/personal/tobias_hoeppner_ff-niendorf_de/ErgG8KriAk5OoU9ESL73XhUBlex5YsPpTfMOuYXhlOH0_w?e=DnQjcG/">FHH-Portal</a><br></br>
              </>}
              <a className="mr-2" href="https://ff-niendorf.de/impressum/">Impressum</a>
              <a className="ml-2" href="https://ff-niendorf.de/datenschutz/">Datenschutzerklärung</a>
            </div>
            <div className="text-center" >
              <img src={logo} alt="Freiwillige Feuerwehr Niendorf Logo" height="auto" width="15%"></img>
            </div>
          </footer>
        </Router>
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
