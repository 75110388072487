import moment from "moment";

class NewAbsence {
  from: moment.Moment;
  to: moment.Moment;
  note: string;

  constructor(from: moment.Moment, to: moment.Moment, note: string) {
    this.from = from;
    this.to = to;
    this.note = note;
  }

  isValid() {
    return this.from.isSameOrBefore(this.to);
  }

  toExportFormat(): { dateFrom: number, dateTo: number, note: string } {
    return { dateFrom: parseInt(this.from.format("YYYYMMDD")), dateTo: parseInt(this.to.format("YYYYMMDD")), note: this.note };
  }

  static createWithDefault(): NewAbsence {
    return new NewAbsence(moment().locale('de'), moment().locale('de'), "");
  }
}

export default NewAbsence;
