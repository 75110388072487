import axios from "axios";
import EventInMonth from "../models/EventInMonth";
import NewEvent from "../models/NewEvent";
import Token from "../models/Token";

const UNKNOWN_ERROR_TEXT = 'Ein unerwarteter Fehler ist aufgetreten.';
export interface EventsInMonthResponse { authError: boolean; events: EventInMonth[] };
export interface AllEventsResponse { authError: boolean; events: EventInMonth[] };
export interface DeleteEventResponse { authError: boolean; success: boolean };
export interface CreateEventResponse { authError: boolean; success: boolean };

const EventService = {
  getEventsInMonth: async function getEventsInMonth(token: Token, yearmonth: number): Promise<EventsInMonthResponse> {
    try {
      const response = await axios.get(
        `/api/events/month`,
        {
          params: {
            yearmonth: yearmonth,
          },
          headers: {
            Authorization: `Bearer ${token.jwt}`
          }
        }
      );
      return Promise.resolve({ authError: false, events: response.data });
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, events: [] })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
  getEvents: async function getEvents(token: Token): Promise<AllEventsResponse> {
    try {
      const response = await axios.get(
        `/api/events`,
        {
          headers: {
            Authorization: `Bearer ${token.jwt}`
          }
        }
      );
      return Promise.resolve({ authError: false, events: response.data });
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, events: [] })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
  createEvent: async function createEvent(token: Token, event: NewEvent): Promise<CreateEventResponse> {
    // date format = yyyyMMDD = 20210321
    try {
      await axios.post(
        `/api/events`,
        event.toExportFormat(),
        {
          headers: {
            Authorization: `Bearer ${token.jwt}`
          }
        }
      );
      return Promise.resolve({ authError: false, success: true })
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, success: false })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
  deleteEventForever: async function deleteEventForever(token: Token, eventId: number): Promise<DeleteEventResponse> {
    try {
      await axios.delete(
        `/api/events/${eventId}`,
        {
          headers: {
            Authorization: `Bearer ${token.jwt}`
          }
        }
      );
      return Promise.resolve({ authError: false, success: true })
    } catch (err: any) {
      if (err.response && err.response.status === 401) {
        return Promise.resolve({ authError: true, success: false })
      }
      return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
    }
  },
}

export default EventService;
