import axios from "axios";
import CurrentVehicleStatus from "../models/CurrentVehicleStatus";
import Token from "../models/Token";

const UNKNOWN_ERROR_TEXT = 'Ein unerwarteter Fehler ist aufgetreten.';
export interface CurrentStatusResponse { authError: boolean; currentVehicleStatus: CurrentVehicleStatus[] };

const StatusService = {
    getCurrentStatus: async function getCurrentStatus(token: Token): Promise<CurrentStatusResponse> {
        try {
            const response = await axios.get(
                `/api/current-vehicle-status`,
                {
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, currentVehicleStatus: response.data });
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, currentVehicleStatus: [] })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
}

export default StatusService;
