import { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Card,
    CardBody,
    CardTitle, Container,
    FormText,
    ListGroup,
    ListGroupItem,
} from 'reactstrap';
import ProtocolLog from '../models/ProtocolLog';
import Token from '../models/Token';
import ProtocolService from '../services/ProtocolService';

interface Props {
    token: Token;
    tokenExpiredCallback: () => void;
}
const ContentProtocol = (p: Props) => {
    const [listOfLogs, setListOfLogs] = useState<ProtocolLog[]>([])

    useEffect(() => {
        ProtocolService.getProtocol(p.token).then(
            v => {
                if (!v.authError) {
                    setListOfLogs(v.logs);
                } else {
                    p.tokenExpiredCallback()
                }
            }
        )
    }, [])

    return (
        <Container>
            <Card className="mt-3 mb-3">
                <CardBody>
                    <CardTitle tag="h4">Protokoll</CardTitle>
                    <FormText color="muted" className="mb-2">
                        Gefährliche Aktionen werden geloggt und hier angezeigt. Oben befinden sich die aktuellen Logs, unten die älteren.
                    </FormText>
                    <ListGroup>
                        {
                            listOfLogs.map((log) =>
                                <ListGroupItem key={log.id}>
                                    User-ID: {log.userId} am {moment.unix(log.timestamp).format('DD.MM.YY HH:mm')} - {log.description}
                                </ListGroupItem>
                            )
                        }
                    </ListGroup>
                </CardBody>
            </Card>
        </Container >
    );
}

export default ContentProtocol;
