import axios from "axios";
import NewEntry from "../models/NewEntry";
import Token from "../models/Token";
import UserEntry from "../models/UserEntry";
import UserLogin from "../models/UserLogin";
import UserProfile from "../models/UserProfile";
import {EntryType} from "../models/EntryType";

export interface GetProfileResponse { authError: boolean; profile: UserProfile };
export interface UpdateProfileResponse { authError: boolean; success: boolean };
export interface UserEntriesOnDateResponse { authError: boolean; entries: UserEntry[] };
export interface CreateUserEntriesOnDateResponse { authError: boolean; success: boolean };
export interface AllUserProfilesResponse { authError: boolean; entries: UserProfile[] };
export interface ToggleUserProfileActivationResponse { authError: boolean; success: boolean };

const UNKNOWN_ERROR_TEXT = 'Ein unerwarteter Fehler ist aufgetreten.';

const UserService = {
    login: async function login(userLogin: UserLogin): Promise<Token> {
        try {
            const response = await axios.post('/api/token', userLogin)
            return Promise.resolve(new Token(response.data))
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.reject(err.response.data.message || 'Passwort oder E-Mail-Adresse sind falsch.')
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
    register: async function register(userProfile: UserProfile): Promise<string> {
        try {
            await axios.post(
                `/api/users`,
                userProfile
            );
            return Promise.resolve('Die Registrierung war erfolgreich. Bitte melden Sie sich nun an.')
        } catch (err: any) {
            if (err.response && err.response.status === 409) {
                return Promise.reject('Es existiert bereits ein Nutzer mit dieser E-Mail-Adresse.')
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
    getProfile: async function getProfile(token: Token): Promise<GetProfileResponse> {
        try {
            const response = await axios.get(
                `/api/users/${token.userId}/profile`,
                {
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, profile: response.data })
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, profile: UserProfile.empty() })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
    updateProfile: async function updateProfile(token: Token, userProfile: UserProfile): Promise<UpdateProfileResponse> {
        try {
            await axios.post(
                `/api/users/${token.userId}/profile`,
                userProfile,
                {
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, success: true })
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, success: false })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
    getUserEntriesOnDate: async function getUserEntriesOnDate(token: Token, date: string, mode: EntryType): Promise<UserEntriesOnDateResponse> {
        // date format = yyyyMMDD = 20210321
        try {
            const response = await axios.get(
                `/api/users/${token.userId}/${mode}`,
                {
                    params: {
                        date: date
                    },
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, entries: response.data })
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, entries: [] })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
    createNewUserEntriesOnDate: async function createNewUserEntriesOnDate(token: Token, date: string, entries: NewEntry[], mode: EntryType): Promise<CreateUserEntriesOnDateResponse> {
        // date format = yyyyMMDD = 20210321
        try {
            const response = await axios.post(
                `/api/users/${token.userId}/${mode}`,
                entries.map(v => v.toExportFormat()),
                {
                    params: {
                        date: date
                    },
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, success: true })
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, success: false })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
    getAllUserProfiles: async function getAllUserProfiles(token: Token): Promise<AllUserProfilesResponse> {
        try {
            const response = await axios.get(
                `/api/users/`,
                {
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, entries: response.data })
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, entries: [] })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
    toggleUserProfileActivation: async function toggleUserProfileActivation(token: Token, userId: number): Promise<ToggleUserProfileActivationResponse> {
        try {
            const response = await axios.put(
                `/api/users/${userId}/activation`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, success: true })
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, success: false })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
    deleteUserProfileForever: async function deleteUserProfileForever(token: Token, userId: number): Promise<ToggleUserProfileActivationResponse> {
        try {
            await axios.delete(
                `/api/users/${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, success: true })
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, success: false })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
    deleteUserEntryForever: async function deleteUserEntryForever(token: Token, userId: number, entryId: number, mode: EntryType): Promise<ToggleUserProfileActivationResponse> {
        try {
            await axios.delete(
                `/api/users/${userId}/${mode}/${entryId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, success: true })
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, success: false })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    }

}

export default UserService;
