import { DatePicker } from "@material-ui/pickers"
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date"
import { Button, Container } from "reactstrap"


interface Props {
    selectedDate: moment.Moment;
    onDateChange: (changedDate: moment.Moment) => void;
}
const DateSelectBar = (p: Props) => {
    return (
        <Container>
            <Button className='mr-1' size="sm" color="secondary" outline onClick={_ => p.onDateChange(p.selectedDate.clone().subtract(1, 'd'))}>{'<'}</Button>
            <DatePicker
                value={p.selectedDate}
                format="DD.MM.yy"
                onChange={(d: MaterialUiPickersDate) => {
                    if (d !== null && d !== undefined) {
                        p.onDateChange(d.clone())
                    }
                }}
                style={{ width: "5.1em", borderBlockColor: "pink", textAlign: "center" }}
            />
            <Button className='mx-1' size="sm" color="secondary" outline onClick={_ => p.onDateChange(p.selectedDate.clone().add(1, 'd'))}>{'>'}</Button>
        </Container>
    )
}

export default DateSelectBar;
