import moment from "moment";

class NewEvent {
  from: moment.Moment;
  to: moment.Moment;
  title: string;
  description: string;

  constructor(from: moment.Moment, to: moment.Moment, title: string, description: string) {
    this.from = from;
    this.to = to;
    this.title = title;
    this.description = description;
  }

  isValid() {
    return this.from.isBefore(this.to) && this.title.length !== 0;
  }

  toExportFormat(): { dateFrom: number, dateTo: number, title: string, description: string } {
    return { dateFrom: this.from.unix(), dateTo: this.to.unix(), title: this.title, description: this.description };
  }

  static createWithDefault(): NewEvent {
    return new NewEvent(moment().locale('de'), moment().locale('de'), "", "");
  }
}

export default NewEvent;
