import moment from "moment";

class NewEntry {
    startHours: number;
    startMinutes: number;
    endHours: number;
    endMinutes: number;

    constructor(sh: number, sm: number, eh: number, em: number) {
        this.startHours = sh;
        this.startMinutes = sm;
        this.endHours = sh > eh ? sh : eh;
        this.endMinutes = em;
    }

    isValid() {
        return this.startHours < this.endHours || this.startHours === this.endHours && this.startMinutes < this.endMinutes;
    }

    overlapsWith(sh: number, sm: number, eh: number, em: number): boolean {
        const thisStart = this.startHours * 100 + this.startMinutes;
        const thisEnd = this.endHours * 100 + this.endMinutes;
        const otherStart = sh * 100 + sm;
        const otherEnd = eh * 100 + em;
        // t: 1300 1400  o: 1100 1300
        // t: 1300 1400  o: 1400 1600
        if (otherEnd <= thisStart || otherStart >= thisEnd) {
            return false;
        }
        return true;
    }

    toExportFormat(): { start: number, end: number } {
        return { start: this.startHours * 100 + this.startMinutes, end: this.endHours * 100 + this.endMinutes };
    }

    static createWithDefault(): NewEntry {
        let startHours = 0;
        let endHours = 0;
        if (moment().get("hours") <= 21) {
            startHours = moment().set("minutes", 0).add(1, "hours").get("hours");
            endHours = moment().set("minutes", 0).add(2, "hours").get("hours");
        } else {
            startHours = 22;
            endHours = 24;
        }
        return new NewEntry(startHours, 0, endHours, 0);
    }
}

export default NewEntry;
