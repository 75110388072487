import jwtDecode, { JwtPayload } from "jwt-decode";

const TOKEN_STORAGE = 'FIREFIGHTER_LOCAL_STORAGE_TOKEN';
interface TokenContent extends JwtPayload {
    role: string;
    userId: number;
    active: boolean;
}
class Token {
    jwt: string;
    exp = -1;
    userId = -1;
    isUserActive = false;
    isAdmin = false;
    isTV = false;

    constructor(jwt: string) {
        this.jwt = jwt;
        this.writeTokenToLocalData(); // automatically replaces old tokens
        try {
            const res = jwtDecode<TokenContent>(this.jwt)
            if (res.exp !== undefined) {
                this.exp = res.exp;
            }
            if (res.userId !== undefined) {
                this.userId = res.userId;
            }
            if ('ADMIN' === res.role) {
                this.isAdmin = true;
            }
            if ('TV' === res.role) {
                this.isTV = true;
            }
            if (res.active !== undefined) {
                this.isUserActive = res.active;
            }
        } catch (err) {
            // console.log(err)
        }
    }

    isValid(): boolean {
        return this.jwt.length !== 0 && this.exp > (Date.now() / 1000);
    }

    writeTokenToLocalData() {
        try {
            localStorage.setItem(TOKEN_STORAGE, this.jwt);
        } catch (err) {
            console.log('Could not save token to browser storage! Error:' + err)
        }
    }

    static createWithTokenFromStorage(): Token {
        const res = localStorage.getItem(TOKEN_STORAGE);
        if (res !== null && res !== undefined) {
            return new Token(res);
        }
        return new Token('');
    }
}

export default Token;
