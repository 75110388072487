import axios from "axios";
import FirefighterRole from "../models/FirefighterRole";

const UNKNOWN_ERROR_TEXT = 'Ein unerwarteter Fehler ist aufgetreten.';

const FirefighterRoleService = {
    getRoles: async function getRoles(): Promise<FirefighterRole[]> {
        // date format = yyyyMMDD = 20210321
        try {
            const response = await axios.get(
                `/api/firefighter-roles`
            );
            return Promise.resolve(response.data);
        } catch (err: any) {
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
}

export default FirefighterRoleService;
