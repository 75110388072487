import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Label,
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Alert,
  Input,
} from 'reactstrap';
import Token from '../models/Token';
import UserAbsence from '../models/UserAbsence';
import NewAbsence from '../models/NewAbsence';
import AbsenceHomeofficeService from '../services/AbsenceHomeofficeService';
import DateSelectBar from './DateSelectBar';
import moment from 'moment';
import {EntryType} from "../models/EntryType";

interface Props {
  token: Token;
  tokenExpiredCallback: () => void;
  mode: EntryType;
}

const ContentUserAbsence = (p: Props) => {
  const history = useHistory();
  const [absences, setAbsences] = useState<UserAbsence[]>([]);
  const [newAbsence, setNewAbsence] = useState<NewAbsence>(NewAbsence.createWithDefault());
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>('');

  useEffect(() => {
    loadAbsences();
  }, [])

  function loadAbsences() {
    AbsenceHomeofficeService.getAbsences(p.token).then(
      v => {
        if (!v.authError) {
          setAbsences(v.absences);
        } else {
          p.tokenExpiredCallback();
        }
      }
    ).catch(e => { console.log(e) });
  }

  function isButtonDisabled(): boolean {
    return !newAbsence.isValid();
  }

  return (
    <Container>
      <Card>
        <CardBody>
          <CardTitle tag="h4">Abwesenheiten</CardTitle>
          <Label>Trage Abwesenheiten ein, um die Planung von Veranstaltungen zu vereinfachen.</Label>
          <Card className="mt-2 mb-2" style={{ backgroundColor: "#f9f9f9" }}>
            <CardBody>
              <CardTitle tag="h5">Neue Abwesenheit</CardTitle>
              <Container>
                <Row>
                  <Col md className="mt-3">
                    <h6>Startdatum der Abwesenheit</h6>
                    <DateSelectBar selectedDate={newAbsence.from} onDateChange={(changedDate) =>
                      setNewAbsence(new NewAbsence(changedDate, newAbsence.to, newAbsence.note))} />
                  </Col>
                  <Col md className="mt-3">
                    <h6>Enddatum der Abwesenheit</h6>
                    <DateSelectBar selectedDate={newAbsence.to} onDateChange={(changedDate) =>
                      setNewAbsence(new NewAbsence(newAbsence.from, changedDate, newAbsence.note))} />
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mt-3">
                    <Label for="absenceNote">Öffentliche Notiz</Label>
                    <Input type="text" name="absenceNote" id="absenceNote" placeholder="Urlaub" value={newAbsence.note}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setNewAbsence(new NewAbsence(newAbsence.from, newAbsence.to, event.target.value))
                      } />
                  </Col>
                </Row>
                <Container className="my-3" >
                  <Alert color="danger" hidden={responseErrorMessage.length === 0}>
                    {responseErrorMessage}
                  </Alert>
                </Container>
              </Container>
            </CardBody>
          </Card>

          <Button className='float-right'
            color="warning"
            disabled={isButtonDisabled()}
            onClick={() => {
              AbsenceHomeofficeService.createAbsence(p.token, newAbsence).then(
                v => {
                  if (!v.authError) {
                    loadAbsences();
                  } else {
                    p.tokenExpiredCallback();
                  }
                  setNewAbsence(NewAbsence.createWithDefault())
                  setResponseErrorMessage('');
                }
              ).catch(e => {
                console.log(e);
                setResponseErrorMessage(e);
              });
            }}>Abwesenheit erstellen
          </Button>
          <Button color="secondary" onClick={() => { history.goBack() }}>Zurück</Button>

          <Card className="mt-4 mb-2">
            <CardBody>
              <CardTitle tag="h5">Meine Abwesenheiten</CardTitle>
              <ListGroup className="mt-3">
                {absences.map((a: UserAbsence) =>
                  <ListGroupItem key={a.id}>
                    {moment(a.dateFrom, "YYYYMMDD").format("DD.MM.YYYY")} bis {moment(a.dateTo, "YYYYMMDD").format("DD.MM.YYYY")}{a.note.length > 0 && ": " + a.note}
                    <Button className="float-right" color="link" onClick={() => {
                      const confirmResult = window.confirm(`Wollen Sie die Abwesenheit wirklich löschen?\n\nStartdatum ${moment(a.dateFrom, "YYYYMMDD").format("DD.MM.YYYY")} bis einschließlich ${moment(a.dateTo, "YYYYMMDD").format("DD.MM.YYYY")}\n`);
                      if (confirmResult) {
                        AbsenceHomeofficeService.deleteUserAbsenceOrHOEntryForever(p.token, a.id, "absences").then((response) => {
                          if (response.authError) {
                            p.tokenExpiredCallback();
                            return
                          }
                          const updatedListOfAbsences: UserAbsence[] = [];
                          absences.forEach(tempE => {
                            if (tempE.id !== a.id) {
                              updatedListOfAbsences.push(tempE);
                            }
                          });
                          setAbsences(updatedListOfAbsences)
                        }).catch(e => console.log(e.toString()));
                      }
                    }}>Eintrag löschen</Button>
                  </ListGroupItem>
                )}
              </ListGroup>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Container>
  );
}

export default ContentUserAbsence;
