import axios from "axios";
import ProtocolLog from "../models/ProtocolLog";
import Token from "../models/Token";

const UNKNOWN_ERROR_TEXT = 'Ein unerwarteter Fehler ist aufgetreten.';

export interface ProtocolResponse { authError: boolean; logs: ProtocolLog[] };

const ProtocolService = {
    getProtocol: async function getProtocol(token: Token): Promise<ProtocolResponse> {
        // timestamp in seconds from 1970
        // 20210101 = 1609459200
        try {
            const response = await axios.get(
                `/api/protocol`,
                {
                    params: {
                        ts: 1609459200
                    },
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, logs: response.data });
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, logs: [] })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
}

export default ProtocolService;
