import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Label,
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Alert,
  Input,
  ListGroupItemHeading,
} from 'reactstrap';
import Token from '../models/Token';
import EventService from '../services/EventService';
import moment from 'moment';
import NewEvent from '../models/NewEvent';
import EventInMonth from '../models/EventInMonth';
import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

interface Props {
  token: Token;
  tokenExpiredCallback: () => void;
}

const ContentEvent = (p: Props) => {
  const history = useHistory();
  const [events, setEvents] = useState<EventInMonth[]>([]);
  const [newEvent, setNewEvent] = useState<NewEvent>(NewEvent.createWithDefault());
  const [responseErrorMessage, setResponseErrorMessage] = useState<string>('');

  useEffect(() => {
    loadEvents();
  }, [])

  function loadEvents() {
    EventService.getEvents(p.token).then(
      v => {
        if (!v.authError) {
          setEvents(v.events);
        } else {
          p.tokenExpiredCallback();
        }
      }
    ).catch(e => { console.log(e) });
  }

  function isButtonDisabled(): boolean {
    return !newEvent.isValid();
  }

  return (
    <Container>
      <Card>
        <CardBody>
          <CardTitle tag="h4">Dienste &amp; Veranstaltungen</CardTitle>
          <Label>Eine neue Veranstaltung oder Dienst erfassen</Label>
          <Card className="mt-2 mb-2" style={{ backgroundColor: "#f9f9f9" }}>
            <CardBody>
              <CardTitle tag="h5">Neue Veranstaltung</CardTitle>
              <Container>
                <Row>
                  <Col md className="mt-3">
                    <h6>Startdatum der Veranstaltung</h6>
                    <DateTimePicker
                      value={newEvent.from}
                      format="DD.MM.yy HH:mm [Uhr]"
                      ampm={false}
                      onChange={(d: MaterialUiPickersDate) => {
                        if (d !== null && d !== undefined) {
                          setNewEvent(new NewEvent(d.clone(), newEvent.to, newEvent.title, newEvent.description))
                        }
                      }}
                      style={{ borderBlockColor: "pink", textAlign: "center" }}
                    />
                  </Col>
                  <Col md className="mt-3">
                    <h6>Enddatum der Veranstaltung</h6>
                    <DateTimePicker
                      value={newEvent.to}
                      format="DD.MM.yy HH:mm [Uhr]"
                      ampm={false}
                      onChange={(d: MaterialUiPickersDate) => {
                        if (d !== null && d !== undefined) {
                          setNewEvent(new NewEvent(newEvent.from, d.clone(), newEvent.title, newEvent.description))
                        }
                      }}
                      style={{ borderBlockColor: "pink", textAlign: "center" }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <Label for="eventTitle">Titel</Label>
                    <Input type="text" name="eventTitle" id="eventTitle" placeholder="Sommerfest" value={newEvent.title}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setNewEvent(new NewEvent(newEvent.from, newEvent.to, event.target.value, newEvent.description))
                      } />
                  </Col>
                </Row>
                <Row>
                  <Col className="mt-3">
                    <Label for="eventDescription">Beschreibung der Veranstaltung</Label>
                    <Input type="text" name="eventDescription" id="eventDescription" placeholder="Wir wollen ein Sommerfest feiern..." value={newEvent.description}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setNewEvent(new NewEvent(newEvent.from, newEvent.to, newEvent.title, event.target.value))
                      } />
                  </Col>
                </Row>
                <Container className="my-3" >
                  <Alert color="danger" hidden={responseErrorMessage.length === 0}>
                    {responseErrorMessage}
                  </Alert>
                </Container>
              </Container>
            </CardBody>
          </Card>

          <Button className='float-right'
            color="warning"
            disabled={isButtonDisabled()}
            onClick={() => {
              EventService.createEvent(p.token, newEvent).then(
                v => {
                  if (!v.authError) {
                    loadEvents();
                  } else {
                    p.tokenExpiredCallback();
                  }
                  setNewEvent(NewEvent.createWithDefault())
                  setResponseErrorMessage('');
                }
              ).catch(e => {
                console.log(e);
                setResponseErrorMessage(e);
              });
            }}>Event erstellen
          </Button>
          <Button color="secondary" onClick={() => { history.goBack() }}>Zurück</Button>

          <Card className="mt-4 mb-2">
            <CardBody>
              <CardTitle tag="h5">Bisherige Veranstaltungen</CardTitle>
              <ListGroup className="mt-3">
                {events.map((a: EventInMonth) =>
                  <ListGroupItem key={a.id}>
                    <ListGroupItemHeading>{a.title}</ListGroupItemHeading>
                    {a.description}
                    <br />
                    <br />
                    {moment(a.dateFrom, "X").format("DD.MM.YYYY HH:mm [Uhr]")} bis {moment(a.dateTo, "X").format("DD.MM.YYYY HH:mm [Uhr]")}
                    <Button className="float-right" color="link" onClick={() => {
                      const confirmResult = window.confirm(`Wollen Sie die Veranstaltung wirklich löschen?\n\nStartdatum ${moment(a.dateFrom, "X").format("DD.MM.YYYY HH:mm [Uhr]")} bis einschließlich ${moment(a.dateTo, "X").format("DD.MM.YYYY HH:mm [Uhr]")}\n`);
                      if (confirmResult) {
                        EventService.deleteEventForever(p.token, a.id).then((response) => {
                          if (response.authError) {
                            p.tokenExpiredCallback();
                            return
                          }
                          const updatedListOfEvents: EventInMonth[] = [];
                          events.forEach(tempE => {
                            if (tempE.id !== a.id) {
                              updatedListOfEvents.push(tempE);
                            }
                          });
                          setEvents(updatedListOfEvents)
                        }).catch(e => console.log(e.toString()));
                      }
                    }}>Eintrag löschen</Button>
                  </ListGroupItem>
                )}
              </ListGroup>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
    </Container>
  );
}

export default ContentEvent;
