import moment from 'moment';
import React, { useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Container, Row, } from 'reactstrap';
import NewEntry from '../models/NewEntry';
import Token from '../models/Token';
import UserService from '../services/UserService';
import ContentMonthCalendar from './ContentMonthCalendar';
import ContentEvent from './ContentEvent';
import ContentEventCalendar from './ContentEventCalendar';
import ContentNewEntry from './ContentNewEntry';
import ContentUserAbsence from './ContentUserAbsence';
import DateSelectBar from './DateSelectBar';
import TimeTableOneDay from './TimeTableOneDay';
import {EntryType} from "../models/EntryType";
import ContentManageHomeofficeEntries from "./ContentManageHomeofficeEntries";

interface Props {
  token: Token;
  tokenExpiredCallback: () => void;
}
const ContentCalendar = (p: Props) => {
  const history = useHistory();
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment().locale('de'));
  const [newEntryResponse, setNewEntryResponse] = useState<string>('');


  const generateContentNewEntry = (entryType: EntryType) => <ContentNewEntry
      mode={entryType}
      token={p.token}
      date={selectedDate}
      serverResponse={newEntryResponse}
      btnFunction={(date: moment.Moment, entries: NewEntry[]) => {
        UserService.createNewUserEntriesOnDate(p.token, date.format('YYYYMMDD'), entries, entryType).then(res => {
          if (res.authError) {
            p.tokenExpiredCallback();
          } else {
            history.goBack();
          }
          setNewEntryResponse('');
        }).catch(e => setNewEntryResponse(e.toString()))
      }} />;

  return (
    <>
      <Switch>
        {p.token.isUserActive && <Route path={'/new-entry'}>
          <Container>
            <Row md="8">
              {generateContentNewEntry("entries")}
            </Row>
          </Container>
        </Route>}
        {p.token.isUserActive && <Route path={'/manage-homeoffice-entries'}>
          <ContentManageHomeofficeEntries token={p.token} tokenExpiredCallback={p.tokenExpiredCallback} />
        </Route>}
        {p.token.isUserActive && <Route path="/absence-calendar">
          <ContentMonthCalendar
              token={p.token}
              tokenExpiredCallback={p.tokenExpiredCallback}
              mode="absences"
          />
        </Route>}
        {p.token.isUserActive && <Route path="/absence">
          <ContentUserAbsence
            token={p.token}
            mode="entries"
            tokenExpiredCallback={p.tokenExpiredCallback} />
        </Route>}
        {p.token.isUserActive && <Route path="/homeoffice">
          <ContentUserAbsence
              token={p.token}
              mode="homeofficeentries"
              tokenExpiredCallback={p.tokenExpiredCallback} />
        </Route>}

        {p.token.isUserActive && <Route path="/event-calendar">
          <ContentEventCalendar
            token={p.token}
            tokenExpiredCallback={p.tokenExpiredCallback} />
        </Route>}
        {p.token.isAdmin && <Route path="/events">
          <ContentEvent
            token={p.token}
            tokenExpiredCallback={p.tokenExpiredCallback} />
        </Route>}
        <Route path={'/tv'}>
          <Container fluid>
            <Row style={{ justifyContent: "center", display: "grid" }}>
              <DateSelectBar selectedDate={selectedDate} onDateChange={(changedDate: moment.Moment) => setSelectedDate(changedDate)} />
            </Row>
            <Row>
              <TimeTableOneDay dateToShow={selectedDate} token={p.token} viewOnly={true} tokenExpiredCallback={p.tokenExpiredCallback} />
              <TimeTableOneDay dateToShow={selectedDate.clone().add(1, "d")} token={p.token} viewOnly={true} tokenExpiredCallback={p.tokenExpiredCallback} />
              <TimeTableOneDay dateToShow={selectedDate.clone().add(2, "d")} token={p.token} viewOnly={true} tokenExpiredCallback={p.tokenExpiredCallback} />
              <TimeTableOneDay dateToShow={selectedDate.clone().add(3, "d")} token={p.token} viewOnly={true} tokenExpiredCallback={p.tokenExpiredCallback} />
            </Row>
          </Container>
        </Route>
        <Route path={'/homeoffice-calendar'}>
          <ContentMonthCalendar
              token={p.token}
              tokenExpiredCallback={p.tokenExpiredCallback}
              mode="homeoffice"
          />
        </Route>
        <Route path={'/'}>
          <Container>
            <Row style={{ justifyContent: "center", display: "grid" }}>
              <DateSelectBar selectedDate={selectedDate} onDateChange={(changedDate: moment.Moment) => setSelectedDate(changedDate)} />
            </Row>
            <Row md="8">
              <TimeTableOneDay dateToShow={selectedDate} token={p.token} viewOnly={false} tokenExpiredCallback={p.tokenExpiredCallback} />
            </Row>
          </Container>
        </Route>
      </Switch>
    </>
  );
}

export default ContentCalendar;
