import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import Token from "../models/Token";
import ContentNewEntry from "./ContentNewEntry";
import moment from "moment/moment";
import NewEntry from "../models/NewEntry";
import UserService from "../services/UserService";
import { useHistory } from "react-router-dom";
import UserAbsence from "../models/UserAbsence";
import AbsenceHomeofficeService from "../services/AbsenceHomeofficeService";
import AbsenceInMonth from "../models/AbsenceInMonth";

interface Props {
  token: Token;
  tokenExpiredCallback: () => void;
}

const ContentManageHomeofficeEntries = (p: Props) => {
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(
    moment().locale("de"),
  );
  const [newEntryResponse, setNewEntryResponse] = useState<string>("");
  const [entries, setEntries] = useState<AbsenceInMonth[]>([]);
  const history = useHistory();

  useEffect(() => {
    loadEntries();
  }, []);

  function loadEntries() {
    AbsenceHomeofficeService.getHOEntries(p.token)
      .then((v) => {
        if (!v.authError) {
          setEntries(v.absences);
        } else {
          p.tokenExpiredCallback();
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return (
    <Container>
      <Row md="8">
        <ContentNewEntry
          mode="homeofficeentries"
          token={p.token}
          date={selectedDate}
          serverResponse={newEntryResponse}
          btnFunction={(date: moment.Moment, entries: NewEntry[]) => {
            UserService.createNewUserEntriesOnDate(
              p.token,
              date.format("YYYYMMDD"),
              entries,
              "homeofficeentries",
            )
              .then((res) => {
                if (res.authError) {
                  p.tokenExpiredCallback();
                } else {
                  history.goBack();
                }
                setNewEntryResponse("");
              })
              .catch((e) => setNewEntryResponse(e.toString()));
          }}
        />
      </Row>
      <Card className="mt-4 mb-2">
        <CardBody>
          <CardTitle tag="h5">Meine HomeOffice-Einträge</CardTitle>
          <ListGroup className="mt-3">
            {entries.map((a: UserAbsence) => (
              <ListGroupItem key={a.id}>
                {moment(a.dateFrom, "YYYYMMDD").format("DD.MM.YYYY")}
                {a.note.length > 0 && ": " + a.note}
                <Button
                  className="float-right"
                  color="link"
                  onClick={() => {
                    const confirmResult = window.confirm(
                      `Wollen Sie den HomeOffice-Eintrag wirklich löschen?\n\nDatum: ${moment(a.dateFrom, "YYYYMMDD").format("DD.MM.YYYY")}${a.note.length > 0 && ": " + a.note}\n`,
                    );
                    if (confirmResult) {
                      AbsenceHomeofficeService.deleteUserAbsenceOrHOEntryForever(
                        p.token,
                        a.id,
                        "homeoffice",
                      )
                        .then((response) => {
                          if (response.authError) {
                            p.tokenExpiredCallback();
                            return;
                          }
                          loadEntries();
                        })
                        .catch((e) => console.log(e.toString()));
                    }
                  }}
                >
                  Eintrag löschen
                </Button>
              </ListGroupItem>
            ))}
          </ListGroup>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ContentManageHomeofficeEntries;
