import {
  Card,
  CardBody,
  CardTitle, Col,
  FormFeedback,
  FormGroup,
  Input, Label, Row
} from 'reactstrap';

interface Props {
  index: number;
  startHours: number;
  startMinutes: number;
  endHours: number;
  endMinutes: number;
  onChange: (sh: number, sm: number, eh: number, em: number) => void;
}
const TimeStartAndEndSelect = (p: Props) => {
  function isValid() {
    return p.startHours < p.endHours || (p.startHours === p.endHours && p.startMinutes < p.endMinutes);
  }

  function isLower2400Valid() {
    return p.endHours == 24 && p.endMinutes > 0
  }

  return (
    <Card key={p.index} className="mb-3" style={{ backgroundColor: "#f9f9f9" }}>
      <CardBody>
        <CardTitle tag="h5">Eintrag #{p.index}</CardTitle>
        <FormGroup>
          <h6>Beginn-Uhrzeit</h6>
          <Row>
            <Col>
              <Label for="startTime">Stunden</Label>
              <Input
                type="select"
                name="startTime"
                onChange={e => p.onChange(parseInt(e.target.value), p.startMinutes, p.endHours, p.endMinutes)}
                defaultValue={p.startHours}
                key={p.index + "startHours"}
                style={{ height: "3rem" }}
              >
                {Array.from(Array(24).keys()).map(
                  e => <option key={e} value={e}>{e}</option>
                )}
              </Input>
            </Col>
            <Col>
              <Label for="startTimeMinutes">Minuten</Label>
              <Input
                type="select"
                name="startTimeMinutes"
                onChange={e => p.onChange(p.startHours, parseInt(e.target.value), p.endHours, p.endMinutes)}
                defaultValue={p.startMinutes}
                key={p.index + "startMinutes"}
                style={{ height: "3rem" }}
              >
                <option value={0}>00</option>
                <option value={15}>15</option>
                <option value={30}>30</option>
                <option value={45}>45</option>
              </Input>
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <h6>End-Uhrzeit</h6>
          <Row>
            <Col>
              <Label for="endTime">Stunden</Label>
              <Input
                type="select"
                name="endTime"
                invalid={!isValid()}
                onChange={e => p.onChange(p.startHours, p.startMinutes, parseInt(e.target.value), p.endMinutes)}
                defaultValue={p.endHours}
                key={p.index + "endHours"}
                style={{ height: "3rem" }}
              >
                {Array.from(Array(25 - p.startHours).keys()).map(
                  e => <option key={e + p.startHours}>{e + p.startHours}</option>
                )}
              </Input>
              <FormFeedback>Die End-Uhrzeit muss nach der Start-Uhrzeit liegen!</FormFeedback>
            </Col>
            <Col>
              <Label for="startTimeMinutes">Minuten</Label>
              <Input
                type="select"
                name="endTimeMinutes"
                invalid={isLower2400Valid()}
                onChange={e => p.onChange(p.startHours, p.startMinutes, p.endHours, parseInt(e.target.value))}
                defaultValue={p.endMinutes}
                key={p.index + "endMinutes"}
                style={{ height: "3rem" }}
              >
                <option value={0}>00</option>
                <option value={15}>15</option>
                <option value={30}>30</option>
                <option value={45}>45</option>
              </Input>
              <FormFeedback>Die End-Uhrzeit darf nicht nach 24:00 Uhr liegen!</FormFeedback>
            </Col>
          </Row>
        </FormGroup>
      </CardBody>
    </Card>
  );
}

export default TimeStartAndEndSelect;
