import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, ListGroup, ListGroupItem, Table } from 'reactstrap';
import TimeStepOnDay from '../models/TimeStepOnDay';
import Token from '../models/Token';
import UserEntry from '../models/UserEntry';
import TimeStepService from '../services/TimeStepService';
import UserService from '../services/UserService';

const MINIMUM_OF_NEEDED_MEMBERS = 6;   // 1x MA; 1x GF; 2x AGT; 2x FM

const COLOR_TRANSPARENT = '#ffffffff';
const COLOR_MISSION = '#259b24';
const COLORS = ['#d50000', '#ff3d00', '#ff9100', '#ffc400', '#ffff00', '#00c853', COLOR_MISSION]; //some, close, full

class TimeEntryColor {
  startColor: string;
  endColor: string;
  constructor(startColor: string, endColor: string) {
    this.startColor = startColor;
    this.endColor = endColor;
  }
}

function getFadeColors(memberCount: number, isMission: boolean): TimeEntryColor {
  if (isMission) {
    return new TimeEntryColor(COLORS[6], COLORS[6])
  }
  if (memberCount >= MINIMUM_OF_NEEDED_MEMBERS) {
    return new TimeEntryColor(COLORS[3], COLORS[4])
  }
  if (memberCount >= 3) {
    return new TimeEntryColor(COLORS[2], COLORS[3])
  }
  if (memberCount >= 2) {
    return new TimeEntryColor(COLORS[1], COLORS[2])
  }
  if (memberCount !== 0) {
    return new TimeEntryColor(COLORS[0], COLORS[1])
  }
  return new TimeEntryColor(COLOR_TRANSPARENT, COLOR_TRANSPARENT);
}

function getTimeStepBorder(step: TimeStepOnDay, dateToShow: moment.Moment, now: moment.Moment) {
  let border = { borderTop: '1px solid #D8D8D899', borderLeft: '' };
  if (step.time.endsWith(':00')) {
    // full hour border
    border.borderTop = '1px solid #6E6E6E';
  }
  if (now.format('YYYYMMDD') === dateToShow.format('YYYYMMDD')
    && step.time === now.subtract(now.minute() % 15, 'm').format('HH:mm')) {
    // now step indicator on the left
    border.borderLeft = '15px solid #043c72';
  }
  return border;
}

interface Props {
  dateToShow: moment.Moment;
  token: Token;
  viewOnly: boolean;
  tokenExpiredCallback: () => void;
}
const TimeTableOneDay = (p: Props) => {
  const history = useHistory();
  const [timeStepsToRender, setTimeStepsToRender] = useState<TimeStepOnDay[]>([]);
  const [userEntries, setUserEntries] = useState<UserEntry[]>([]);
  const now = moment().locale('de');

  useEffect(() => {
    if (!p.viewOnly) {
      UserService.getUserEntriesOnDate(p.token, p.dateToShow.format('YYYYMMDD'), "entries").then(
        v => {
          if (!v.authError) {
            setUserEntries(v.entries);
          } else {
            p.tokenExpiredCallback()
          }
        }
      )
    }
  }, [p.dateToShow])

  useEffect(() => {
    TimeStepService.getTimeStepsOnDate(p.token, p.dateToShow.format('YYYYMMDD'), "entries").then(
      v => {
        if (!v.authError) {
          setTimeStepsToRender(v.timeSteps);
        } else {
          p.tokenExpiredCallback()
        }
      }
    )
  }, [p.dateToShow])

  const scrollToCurrentTime = () => {
    const nowTimeSlotId = 'timeStep-' + now.format('HH-mm');
    const element = document.getElementById(nowTimeSlotId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  useEffect(scrollToCurrentTime, [timeStepsToRender]);

  return (
    <Col style={{ paddingRight: 10 }} >
      <h4>Einträge am {p.dateToShow.format("dddd")} den {p.dateToShow.format("DD.MM.YY")}</h4>
      <Table borderless>
        <thead>
          <tr key={p.dateToShow.format("DD.MM.YY")}>
            <th style={{ width: '50px' }}></th>
            <th key={'countColumn'} className="text-center" style={{ padding: 0 }}>Anwesenheit</th>
          </tr>
        </thead>
        <tbody>
          {timeStepsToRender.map((step: TimeStepOnDay, _tableRowIndex: number) =>
            <tr key={step.time} style={getTimeStepBorder(step, p.dateToShow, now)} id={'timeStep-' + step.time.replace(':', '-')}>
              <th className='text-right small w-10' style={{
                paddingTop: 0,
                paddingBottom: '0.5rem',
                fontWeight: `${step.isMission ? 'bold' : ''}` as 'bold',
                color: `${step.isMission ? '#fff' : '#000'}`,
                backgroundColor: `${step.isMission ? COLOR_MISSION : COLOR_TRANSPARENT}`
              }}>{step.time.endsWith(':00') ? step.time : step.time.substring(3)}</th>
              <td key={step.time} style={{
                margin: 0,
                padding: 0,
                borderRadius: "1rem",
                backgroundImage: `linear-gradient(to right, ${getFadeColors(step.members.length, step.isMission).startColor}, ${getFadeColors(step.members.length, step.isMission).endColor})`
              }}>
                {step.members.map((u, i) => <span key={i} className="badge badge-secondary mx-1" style={{ marginTop: 0, marginBottom: 0 }}>{u}</span>)}
                {step.members.length > 0 ? <span className="ml-2">({step.members.length})</span> : ''}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      {!p.viewOnly && p.token.isUserActive &&
        <div>
          <Card className="mt-1 mb-3">
            <CardBody>
              <CardTitle tag="h5">Meine Einträge
                <Button className="float-right" color="warning" onClick={() => history.push('/new-entry')}>Zeit erfassen</Button>
              </CardTitle>

              <ListGroup className="mt-3">
                {userEntries.map((e: UserEntry) =>
                  <ListGroupItem key={e.id}>
                    {e.start} bis {e.end}
                    <Button className="float-right" color="link" onClick={() => {
                      const confirmResult = window.confirm(`Wollen Sie den Eintrag wirklich löschen?\n\nZeit: ${e.start} bis ${e.end}\n\nKurzfristige Löschungen können den Einsatz gefährden!`);
                      if (confirmResult) {
                        UserService.deleteUserEntryForever(p.token, p.token.userId, e.id, "entries").then((response) => {
                          if (response.authError) {
                            p.tokenExpiredCallback();
                            return
                          }
                          const updatedListOfEntries: UserEntry[] = [];
                          userEntries.forEach(tempE => {
                            if (tempE.id !== e.id) {
                              updatedListOfEntries.push(tempE);
                            }
                          });
                          setUserEntries(updatedListOfEntries)
                        }).catch(err => console.log(err.toString()));
                      }
                    }}>Eintrag löschen</Button>
                  </ListGroupItem>
                )}
              </ListGroup>
            </CardBody>
          </Card>
          <Card className="mt-1 mb-3">
            <CardBody>
              <CardTitle tag="h5">Abwesenheiten
                <Button className="float-right ml-3 mt-2" color="warning" onClick={() => history.push('/absence')}>Abwesenheiten verwalten</Button>
                <Button className="float-right mt-2" color="warning" onClick={() => history.push('/absence-calendar')}>Abwesenheitskalender ansehen</Button>
              </CardTitle>
            </CardBody>
          </Card>
          <Card className="mt-1 mb-3">
            <CardBody>
              <CardTitle tag="h5">Dienste &amp; Veranstaltungen
                {p.token.isAdmin &&
                  <Button className="float-right ml-3 mt-2" color="warning" onClick={() => history.push('/events')}>Veranstaltungen verwalten</Button>
                }
                <Button className="float-right mt-2" color="warning" onClick={() => history.push('/event-calendar')}>Veranstaltungskalender ansehen</Button>
              </CardTitle>
            </CardBody>
          </Card>
        </div>
      }
    </Col>
  );
}

export default TimeTableOneDay;
