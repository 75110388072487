import axios from "axios";
import TimeStepOnDay from "../models/TimeStepOnDay";
import Token from "../models/Token";
import {EntryType} from "../models/EntryType";

const UNKNOWN_ERROR_TEXT = 'Ein unerwarteter Fehler ist aufgetreten.';

export interface TimeStepsOnDateResponse { authError: boolean; timeSteps: TimeStepOnDay[] };

const TimeStepService = {
    getTimeStepsOnDate: async function getTimeStepsOnDate(token: Token, date: string, mode: EntryType): Promise<TimeStepsOnDateResponse> {
        // date format = yyyyMMDD = 20210321
        try {
            const response = await axios.get(
                `/api/${mode === "entries" ? "" : "homeoffice"}timesteps`,
                {
                    params: {
                        date: date
                    },
                    headers: {
                        Authorization: `Bearer ${token.jwt}`
                    }
                }
            );
            return Promise.resolve({ authError: false, timeSteps: response.data });
        } catch (err: any) {
            if (err.response && err.response.status === 401) {
                return Promise.resolve({ authError: true, timeSteps: [] })
            }
            return Promise.reject(err.response && err.response.data && err.response.data.message ? err.response.data.message : UNKNOWN_ERROR_TEXT)
        }
    },
}

export default TimeStepService;
