import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Alert,
    Button,
    Card,
    CardBody,
    CardTitle, Container,
    Form,
    FormGroup,
    Input, Label
} from 'reactstrap';
import UserLogin from '../models/UserLogin';

interface Props {
    loginFunction: (userLogin: UserLogin) => void;
    loginMessage: string;
}
const ContentLogin = (p: Props) => {
    const [user, setUser] = useState<UserLogin>(new UserLogin('', ''));
    return (
        <Container>
            <Card className="mt-4">
                <CardBody>
                    <CardTitle tag="h4">Anmeldung</CardTitle>
                    <p>Fülle alle Felder aus und drücke auf Anmelden.</p>
                    <Form>
                        <FormGroup>
                            <Label for="exampleEmail">E-Mail-Adresse</Label>
                            <Input type="email" name="email" id="idEmail" placeholder="E-Mail-Adresse" value={user.username}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setUser(new UserLogin(event.target.value, user.password));
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="examplePassword">Passwort</Label>
                            <Input type="password" name="password" id="idPassword" placeholder="Passwort" value={user.password}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setUser(new UserLogin(user.username, event.target.value));
                                }} />
                        </FormGroup>
                        <Container className="my-3" >
                            <Alert color="danger" hidden={p.loginMessage.length === 0} >
                                {p.loginMessage}
                            </Alert>
                        </Container>
                        <Button type="submit" className='float-right' color="warning" disabled={!user.isValid()} onClick={(e) => {
                            e.preventDefault(); // avoid reloading of page
                            p.loginFunction(user);
                        }}>Anmelden</Button>
                    </Form>
                </CardBody>
            </Card>
            <Link to='/register'>Noch keinen Account? Jetzt registrieren!</Link>
        </Container>
    );
}

export default ContentLogin;
