
export const emailRegex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
class UserLogin {
    username: string;
    password: string;
    constructor(email: string, password: string) {
        this.username = email;
        this.password = password;
    }

    isValid() {
        return this.password.length >= 8 && emailRegex.test(this.username);
    }
}

export default UserLogin;
