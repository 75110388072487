import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Card,
    CardBody,
    CardTitle,
    Alert,
    FormText,
} from 'reactstrap';
import Token from '../models/Token';
import UserProfile from '../models/UserProfile';
import FirefighterRole from '../models/FirefighterRole';
import FirefighterRoleService from '../services/FirefighterRoleService';
import UserService from '../services/UserService';

interface Props {
    token: Token;
    isUserProfile: boolean;
    headline: string;
    serverResponse: string;
    btnText: string;
    btnFunction: (user: UserProfile) => void;
    tokenExpiredCallback: () => void;
}
const ContentUserProfile = (p: Props) => {
    const history = useHistory();
    const [userProfile, setUserProfile] = useState<UserProfile>(UserProfile.empty());
    const [availableRoles, setAvailableRoles] = useState<FirefighterRole[]>([]);
    useEffect(() => {
        FirefighterRoleService.getRoles().then(
            v => {
                setAvailableRoles(v);
            }
        )
    }, [])
    useEffect(() => {
        if (p.isUserProfile) {
            UserService.getProfile(p.token).then(
                v => {
                    if (!v.authError) {
                        setUserProfile(v.profile);
                    } else {
                        p.tokenExpiredCallback()
                    }
                }
            )
        }
    }, [])

    function toggleUserRole(roleShortForm: string) {
        var updatedRoles = userProfile.roles;
        const index = updatedRoles.findIndex((r: string) => r === roleShortForm);
        if (index === -1) {
            updatedRoles.push(roleShortForm);
        } else {
            updatedRoles = updatedRoles.filter((r) => r !== roleShortForm);
        }
        setUserProfile(new UserProfile(userProfile.id, userProfile.firstName,
            userProfile.lastName, userProfile.email,
            userProfile.password, updatedRoles, userProfile.activated));
    }

    function isButtonDisabled(): boolean {
        if (p.isUserProfile) {
            return false;
        }
        return userProfile.isFilledOrHint() !== '';
    }

    return (
        <Container>
            <Card className="mt-4">
                <CardBody>
                    <CardTitle tag="h4">{p.headline}</CardTitle>
                    <Form>
                        <FormGroup>
                            <Label for="idFirstName">Vorname</Label>
                            <Input type="text" name="firstName" id="idFirstName" placeholder="Max" value={userProfile.firstName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setUserProfile(new UserProfile(userProfile.id, event.target.value, userProfile.lastName, userProfile.email, userProfile.password, userProfile.roles, userProfile.activated));
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="idLastName">Nachname</Label>
                            <Input type="text" name="lastName" id="idLastName" placeholder="Mustermann" value={userProfile.lastName}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setUserProfile(new UserProfile(userProfile.id, userProfile.firstName, event.target.value, userProfile.email, userProfile.password, userProfile.roles, userProfile.activated));
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <Label for="idEmail">E-Mail-Adresse</Label>
                            <Input type="email" name="email" id="idEmail" placeholder="max@mustermann.de" value={userProfile.email}
                                disabled={p.isUserProfile}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setUserProfile(new UserProfile(userProfile.id, userProfile.firstName, userProfile.lastName, event.target.value, userProfile.password, userProfile.roles, userProfile.activated));
                                }} />
                        </FormGroup>
                        <FormGroup hidden={p.isUserProfile}>
                            <Label for="idPassword">Passwort</Label>
                            <Input disabled={p.isUserProfile} type="password" name="password" id="idPassword" placeholder="Passwort" value={userProfile.password}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setUserProfile(new UserProfile(userProfile.id, userProfile.firstName, userProfile.lastName, userProfile.email, event.target.value, userProfile.roles, userProfile.activated));
                                }} />
                            <FormText color="muted">
                                Das Passwort muss min. 8 Zeichen lang sein und muss aus min. einer Zahl und einem Buchstaben bestehen. Es dürfen keine Leerzeichen enthalten sein.
                            </FormText>
                        </FormGroup>
                        <Label>Deine Teamrollen</Label>
                        <Container>
                            {availableRoles.map(r =>
                                <Button key={r.shortForm} outline={userProfile.roles.findIndex((e) => e === r.shortForm) === -1}
                                    onClick={() => {
                                        toggleUserRole(r.shortForm);
                                    }} color='primary' className="mr-1 mb-1">
                                    {r.longForm} ({r.shortForm})
                                </Button>
                            )}
                        </Container>
                        <Container className="my-3" >
                            <Alert color="danger" hidden={p.serverResponse.length === 0} >
                                {p.serverResponse}
                            </Alert>
                        </Container>
                        <Button color="secondary" onClick={() => { history.goBack() }}>Zurück</Button>
                        <Button className='float-right'
                            color="warning"
                            disabled={isButtonDisabled()}
                            onClick={() => p.btnFunction(userProfile)}>{p.btnText}
                        </Button>
                    </Form>
                </CardBody>
            </Card>
        </Container>
    );
}

export default ContentUserProfile;
