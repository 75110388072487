import React, { useEffect, useState } from 'react';
import {
  Container,
  Button,
  Row,
  Col,
  Badge,
  UncontrolledTooltip,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroup,
  CardTitle,
  CardBody,
  Card,
} from 'reactstrap';
import Token from '../models/Token';
import EventService from '../services/EventService';
import moment from 'moment';
import EventInMonth from '../models/EventInMonth';

interface Props {
  token: Token;
  tokenExpiredCallback: () => void;
}

const ContentEventCalendar = (p: Props) => {
  const today = moment().locale('de');
  const [currentYearMonth, setCurrentYearMonth] = useState<moment.Moment>(today.clone().set("D", 1).set("h", 0).set("m", 0).set("s", 0).set("ms", 0));
  const [events, setEvents] = useState<EventInMonth[]>([]);

  useEffect(() => {
    loadEventsInMonth(parseInt(currentYearMonth.format('YYYYMM')));
  }, [])

  function loadEventsInMonth(yearmonth: number) {
    EventService.getEventsInMonth(p.token, yearmonth).then(
      v => {
        if (!v.authError) {
          setEvents(v.events);
        } else {
          p.tokenExpiredCallback();
        }
      }
    ).catch(e => { console.log(e) });
  }

  // returns the list of this month plus the weeknumbers
  function daysToView(): string[] {
    const weekday = currentYearMonth.isoWeekday();
    const itemsToDisplay = [];
    const weeknumber = currentYearMonth.format("W");
    itemsToDisplay.push(weeknumber);
    for (let index = 0; index < weekday - 1; index++) {
      itemsToDisplay.push("");
    }
    let lastWeeknum = weeknumber;
    const copy = currentYearMonth.clone();
    while (true) {
      if (currentYearMonth.month() !== copy.month()) {
        while (copy.isoWeekday() !== 1) {
          itemsToDisplay.push("");
          copy.add(1, "day");
        }
        break;
      }
      if (lastWeeknum !== copy.format("W")) {
        lastWeeknum = copy.format("W");
        itemsToDisplay.push(copy.format("W"));
      }
      itemsToDisplay.push(copy.format("D"));
      copy.add(1, "day");
    }
    return itemsToDisplay;
  }

  function buildWeeknumCol(weeknum: string): JSX.Element {
    return <Col xs="auto" className={"font-italic small m-0 p-0"}>
      {weeknum.length === 1 ? <>&nbsp;&nbsp;</> : ""}{weeknum}
    </Col>
  }

  function calcEventsOnThisDay(daynum: string): string[] {
    const eventsOnThisDay = [];
    const day = currentYearMonth.clone().set("D", parseInt(daynum));
    for (const event of events) {
      const from = moment(event.dateFrom, "X");
      const to = moment(event.dateTo, "X");
      if (day.isBetween(from, to, "days", "[]")) {
        eventsOnThisDay.push(event.title);
      }
    }
    return eventsOnThisDay;
  }

  function buildDayCol(daynum: string): JSX.Element {
    if (daynum === "") {
      return <Col className="border m-0 p-1"></Col>
    }
    const listOfEventsOnThisDay = calcEventsOnThisDay(daynum);
    const key = "keyDay" + daynum;
    let styleClassNames = "border m-0 p-1"
    if (daynum === today.format('D') && currentYearMonth.format('YYYYMM') === today.format('YYYYMM')) {
      styleClassNames = styleClassNames + " border-primary font-weight-bold"
    }
    return <Col id={key} key={key} className={styleClassNames} style={{ minHeight: "3rem" }}>
      {daynum}<br></br>
      {listOfEventsOnThisDay.length !== 0 &&
        <span style={{ float: 'right', paddingRight: "7%" }}>
          <Badge color="warning" style={{ fontSize: "1em" }}>{listOfEventsOnThisDay.length}</Badge>
          <UncontrolledTooltip placement="bottom" target={key}>
            <div>
              {listOfEventsOnThisDay.map((v, _i) => <>{v}<br></br></>)}
            </div>
          </UncontrolledTooltip>
        </span>
      }
    </Col>
  }

  function buildMonthView(): JSX.Element {
    const days = daysToView();
    const listOfWeeks = [];
    for (let index = 0; index < 6; index++) {
      listOfWeeks.push(
        <Row key={"week" + index}>
          {days.slice(index * 8, index * 8 + 8).map((v, i) => i === 0 ? buildWeeknumCol(v) : buildDayCol(v))}
        </Row>
      );
    }
    return (
      <>
        {listOfWeeks.map((v) => v)}
      </>
    )
  }

  const HEADLINES = [" #", "Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];
  function headline() {
    return (<Row>
      {HEADLINES.map((v, i) =>
        <Col xs={i === 0 ? "auto" : ""} key={v + "" + i} className={i === 0 ? "h6 m-0 p-0" : "h6 m-0 p-0"}>
          {v}&nbsp;
        </Col>
      )}
    </Row>)
  }

  return (
    <Container>
      <h4>Veranstaltungskalender</h4>
      <Container>
        <Row className="mb-2 justify-content-center">
          <Col xs="2" md="1">
            <Button className='mr-1' size="sm" color="secondary" outline onClick={_ => {
              const yearMonthMinusOneMonth = currentYearMonth.clone().subtract(1, 'months');
              setCurrentYearMonth(yearMonthMinusOneMonth);
              loadEventsInMonth(parseInt(yearMonthMinusOneMonth.format('YYYYMM')));
            }}>{'<'}</Button>
          </Col>
          <Col xs="5" md="3" lg="2" className="mt-1">
            {currentYearMonth.format("MMMM Y")}
          </Col>
          <Col xs="2" md="1">
            <Button className='mx-1' size="sm" color="secondary" outline onClick={_ => {
              const yearMonthPlusOneMonth = currentYearMonth.clone().add(1, 'months');
              setCurrentYearMonth(yearMonthPlusOneMonth);
              loadEventsInMonth(parseInt(yearMonthPlusOneMonth.format('YYYYMM')));
            }}>{'>'}</Button>
          </Col>
        </Row>
        {headline()}
        {buildMonthView()}
      </Container>
      {events.length !== 0 && <Card className="mt-4 mb-2">
        <CardBody>
          <CardTitle tag="h5">Geplante Veranstaltungen</CardTitle>
          <ListGroup className="mt-3">
            {events.map(
              (a: EventInMonth, index: number) =>
              (
                <ListGroupItem key={a.id + "" + index}>
                  <ListGroupItemHeading>{a.title}</ListGroupItemHeading>
                  {a.description}
                  <br />
                  <br />
                  {moment(a.dateFrom, "X").format("DD.MM.YYYY HH:mm [Uhr]")} bis {moment(a.dateTo, "X").format("DD.MM.YYYY HH:mm [Uhr]")}
                </ListGroupItem>
              )
            )}
          </ListGroup>
        </CardBody>
      </Card>}
    </Container>
  );
}

export default ContentEventCalendar;
